@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header_text {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	margin-bottom: 8px;
	letter-spacing: normal;
	text-align: center;
}

.body_text {
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 16px;
	letter-spacing: normal;
	text-align: center;
}

.email_verification {
	padding: 8px 8px 0;

	h1 {
		@extend .header_text;
	}

	p {
		@extend .body_text;
	}

	input {
		text-align: center;
	}

	.actions {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		button {
			flex: 1;
			margin: 0 4px;
			border-radius: 40px;
		}
	}
}

.nc_connect {
	h1 {
		@extend .header_text;
	}

	p {
		@extend .body_text;
	}

	.otherLogin {
		button {
			width: 100%;
			background: #2e2c6a;
			margin-bottom: 15px;

			img {
				float: left;
				width: 14px;
				position: absolute;
				left: 20px;
			}

			svg {
				color: #ffffff;
				margin-right: 10px;
				font-size: 14px;
				position: absolute;
				left: 20px;
				top: 14px;
			}
		}

		a {
			display: block;

			button {
				border-radius: 4px;
				padding: 8px 24px;

				p {
					color: #fff;
					margin: 0;
				}
			}
		}
	}

	.cancel {
		width: 100%;
		border-radius: 25px;
		background: #4e4bde;
		margin-bottom: 14px;
	}
}
