.dialog {
	z-index: 997 !important;

	.dialog_paper {
		width: 348px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		// background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		background: linear-gradient(
			180deg,
			rgba(45, 42, 180, 0.5) 0%,
			rgba(20, 18, 78, 0.5) 100%
		);
		backdrop-filter: blur(3px);

		.dialog_content {
			padding: 24px;

			h2 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1.25rem;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
				letter-spacing: normal;
				margin-bottom: 10px;
				text-wrap: nowrap;
			}

			h3 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1rem;
				font-style: normal;
				font-weight: 700;
				line-height: 30px;
				letter-spacing: normal;

				&.header_gems {
					margin-bottom: 0px;
					font-weight: 600;
				}

				&.mb_10 {
					margin-bottom: 10px;
				}
			}

			h1 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1.5rem;
				font-style: normal;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: normal;
			}

			span {
				font-size: 0.75rem;
				color: #fff;
				font-weight: 600;
				line-height: 16px;
				text-wrap: nowrap;
			}

			p {
				letter-spacing: normal;
				color: #fff;
				opacity: 0.5;
				text-align: center;
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 300;
				line-height: 16px;
				margin-bottom: 10px;
			}

			.full_width {
				width: 100%;
			}

			.grid_img {
				padding: 0px !important;
				height: 17px;
				width: 17px;
			}

			.flex_grid {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 3px !important;
			}
			.flex_grid_token {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px !important;
				gap: 6px;
			}
			.align_left {
				text-align: left;
			}
			.flex_grid_with_gap {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 3px !important;
				column-gap: 6px;
			}

			.flex_grid_Gems {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				padding: 12px 0px !important;
			}

			.custom_padding {
				padding: 0px 0px 10px 0px;
			}
			.flex_Gems {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 18px 0px 14px 0px;
				& h3 {
					flex: 1 1 13%;
					max-width: 13%;
					text-wrap: nowrap;

					&.flex_width {
						flex: 1 1 30%;
						max-width: 30%;
					}
				}

				& img {
					flex: 1 1 19px;
					max-width: 19px;
					margin: 0px;
					margin-bottom: 0px;
					margin-right: 5px;
				}
			}

			.flex_80 {
				flex: 1 1 80%;
				max-width: 80%;
			}

			.mb_8 {
				margin-bottom: 8px;
				display: flex;
				img {
					width: 17px;
					height: 17px;
				}
			}

			.padding_16 {
				padding: 16px !important;
			}

			.icon_after_text {
				display: flex;
				img {
					width: 15px;
					height: 15px;
				}
			}

			.first_Item {
				padding: 16px !important;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.token_convert {
				justify-content: space-between;
				align-items: center;
			}

			.no_padding {
				padding: 0px !important;
			}

			.actions {
				display: flex;
				justify-content: center;

				button {
					border-radius: 25px;
					background: #4e4bde;
					color: #fff;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
					font-weight: 700;
					line-height: 24px;
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
					font-weight: 700;
					line-height: 24px;
				}
			}

			.icon_btns {
				display: flex;
				justify-content: space-between;
				margin-bottom: 12px;
				width: 100%;

				.left_dir {
					width: 50%;
					display: flex;
					justify-content: flex-start;
				}

				.right_dir {
					width: 50%;
					display: flex;
					justify-content: flex-end;
				}
			}

			.schedules_data_content {
				margin: 24px 0px;

				.grid_data {
					display: flex;
					justify-content: space-between;
					height: 32px;
					align-items: center;
					padding: 4px 16px 4px 16px;

					span {
						font-size: 1rem;
						color: #fff;
						font-weight: 400;
						line-height: 24.34px;
					}
				}
			}

			.center {
				text-align: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				width: 100%;

				img {
					position: relative;
					top: 2px;
					display: inline-block;
					width: 32px;
					height: auto;
					margin-right: 10px;
				}

				.countdown {
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 4px;
				}

				.progress_container {
					position: relative;
					max-width: 100%;
					width: 100%;
					margin: 0;
					background: none;
				}

				.flex_Gems {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 8px auto;

					& h3 {
						flex: 1 1 20%;
						max-width: 20%;
						text-wrap: nowrap;
						font-size: 1rem !important;

						&.flex_width {
							flex: 1 1 40%;
							max-width: 40%;
							font-size: 0.75rem !important;
							font-weight: 600;
						}
					}

					& img {
						flex: 1 1 17px;
						max-width: 17px;
						margin: 0px;
					}
				}

				h1 {
					display: inline-block;
				}

				.speedUpBtn {
					display: block;
					// margin-top: 12px;
					font-size: 16px;
					font-weight: 600;
					width: 100%;
					border: none;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 8px;
					background: linear-gradient(
						270deg,
						#4e4bde 33.75%,
						#bd02b2 167.82%,
						#5348dc 251.62%
					);
					border-radius: 12px;

					img {
						width: 16px;
						margin-right: 4px;
					}

					&.active {
						background: linear-gradient(
							270deg,
							#4e4bde,
							#bd02b2,
							#5348dc,
							#4e4bde
						);
						background-size: 400% 400%;
						animation: Gradient 6s linear infinite forwards;
					}
				}
			}
		}
	}

	&.large {
		.dialog_paper {
			width: auto;
		}

		.dialog_content {
			.actions {
				button {
					padding: 26px 32px;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}
			}
		}
	}

	&.warning {
		.dialog_paper {
			width: auto;
			overflow: visible;
		}

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -90px;
			z-index: 10;
			width: 340px;
			height: 155px;
		}

		.dialog_content {
			overflow: auto;

			h2 {
				margin-top: 60px;
			}

			p {
				text-align: left;
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 30px;
			}

			.actions {
				.approve_btn {
					background: #11113a;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}

				.close_btn {
					background: #2e2c6a;
					color: #fff;
					font-weight: 700;
					line-height: 24px;
					height: 40px;
					min-width: 142px;
					padding: 0 35px;
				}
			}
		}
	}
}

.showPercent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	h3 {
		font-family: Titillium Web;
		font-size: 16px;
		font-weight: 700;
		line-height: 30px;
		text-align: left;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.progressPercent {
		margin-bottom: 10px;
		height: 28px;
		padding: 4px 8px 4px 8px !important;
		border-radius: 8px !important;
		background: #000000;
		font-family: Titillium Web;
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 20px !important;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		&:after {
			content: '%';
		}
	}
}
