.bar_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	height: 30px;

	.progress_container {
		position: relative;
		background: none;
		width: 100%;
		height: 13px;
		padding: 1px;
		border-radius: 4px;
		border: 1px solid #4e4bde;

		.progress_bar {
			padding: 0;
			position: relative;
			height: 100%;
			gap: 0px;
			border-radius: 2px;
			background: linear-gradient(360deg, #4f4cde 0%, #bd02b2 66.67%);
			box-shadow: 0px 4px 4px 0px #ffffff40 inset;
			transition: width 0.1s ease-in-out;
		}
	}

	.progressPercent {
		background: #000000 !important;
		font-family: 'Titillium Web', sans-serif;
		font-weight: 600;
		line-height: 13px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
		height: 13px;
		padding: 2px 4px 2px 4px;
		border-radius: 4px;

		&:after {
			content: '%';
		}
	}
}
