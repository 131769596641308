@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.whitelisted_wallet {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	border: 1px solid #4e4bde;
	background: linear-gradient(
		207.51deg,
		rgba(78, 75, 222, 0.25) -8.93%,
		rgba(26, 23, 62, 0.25) 89.5%
	);
	padding: 16px;
	margin-right: 0;

	@include media($phone_all...) {
		padding: 26px 25px;
		margin-top: 0px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.wallet_info {
		.help_icon {
			right: 15px !important;
			top: 15px !important;
			max-width: 120px;
			text-align: center;
			cursor: pointer;
			opacity: 0.5;
			height: 20px;
			margin-bottom: 10px;

			svg {
				font-size: 15px;
				margin: 0;

				@include media($phone_all...) {
					font-size: 20px;
				}
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	.text {
		margin-bottom: 18px;
		width: 100%;
		justify-content: center;
		display: flex;
		align-items: center;

		span {
			display: flex;
			justify-content: center;
			margin-left: 10px;
		}
	}

	button {
		font-size: 16px !important;
		font-weight: 600;
		background-image: none !important;
		padding: 0.25rem 0.5rem !important;

		&.remove {
			padding: 0 !important;
			min-width: initial;
			background: rgba(38, 34, 98, 0.5) !important;
			border-radius: 6px;
			color: rgba(255, 255, 255, 0.5);
			flex: 1 1 10%;
			max-width: 10%;
			padding: 9px 18px !important;

			svg {
				font-size: 14px;
			}

			&::before {
				background: none;
			}
		}
	}

	button::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 5px;
		border: 0.15rem solid transparent;
		background: linear-gradient(45deg, #016eda, #d900c0) border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
	}

	@include media($phone_all...) {
		margin-top: 10px;
	}

	.content {
		display: flex;

		@include media($phone_all...) {
			flex: 1 1 75%;
			max-width: 75%;
		}

		& > div {
			flex: 1;
		}

		.info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: left;

			p {
				color: #fff;
				font-family: var(--font-manrope);
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 22px;
				letter-spacing: -0.252px;
				margin-bottom: 8px;
				text-align: center;
				max-width: 120px;

				&.address {
					opacity: 0.5;
				}

				svg {
					margin-right: 8px;
					font-size: 18px;
				}
			}

			.address {
				font-size: 14px;
				font-weight: 600;
				background: rgba(38, 34, 98, 0.5);
				color: rgba(255, 255, 255, 0.5) !important;
				align-content: center;
				text-align: center;
				padding: 8px;
				border-radius: 25px;
				max-width: 120px;
			}

			button {
				font-family: 'Titillium Web', sans-serif;
				width: 100%;
				border-radius: 25px;
				background: rgba(38, 34, 98, 1);
				margin-top: 0;
				max-width: 120px;
				font-size: 16px;
				padding: 8px !important;
				font-weight: 600;

				@include media($phone_all...) {
					max-width: 140px;
					padding: 8px 14px !important;
				}

				&.remove {
					background: rgba(38, 34, 98, 0.5);
					color: rgba(255, 255, 255, 0.5) !important;
					align-content: center;

					svg {
						font-size: 20px;
						opacity: 0.5;
						margin-left: 5px;
					}
				}

				&.remove::before {
					border: none !important;
				}
			}
		}

		.white_address_container {
			display: flex;
			width: 100%;
			gap: 10px;
		}

		.white_address {
			font-size: 16px;
			font-weight: 300;
			padding: 4px 18px;
			background: rgba(38, 34, 98, 0.5);
			border-radius: 6px;
			color: rgba(255, 255, 255, 0.5);
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1;

			@include media($phone_all...) {
				flex: 1 1 75%;
				max-width: 75%;
			}

			svg {
				color: rgba(255, 255, 255, 0.5);
				font-size: 14px;
				margin-right: 5px;
			}
		}

		.buttons {
			display: flex;
			justify-content: space-around;
			margin-top: 18px;

			p {
				justify-content: center;
				align-content: center;
				font-weight: 600;
				font-size: 16px;
				margin-top: 8px;
				margin-right: 10px;
			}

			button {
				border-radius: 12px;
				background: #4e4bde;
				margin: 8px 4px 0;
				padding: 4px 17px !important;
				font-weight: 600;
				font-size: 16px;

				@include media($phone_all...) {
					max-width: 140px;
					padding: 8px 14px !important;
				}

				&.remove {
					background: #262262;
				}
			}

			.transferIn,
			.transferOut {
				min-width: 60px;
			}

			.transferOut {
				background: #262262;
			}

			.transferIn::before,
			.transferOut::before {
				border: none;
				border-radius: initial;
			}
		}
	}
}
