.search_item {
	max-height: 15.75rem;
	overflow-y: auto;
	margin-top: 2px;

	label {
		> span {
			padding-top: 10px;
			padding-bottom: 5px;
		}
	}
}
