@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.reward_progression {
	border: 1px solid #ba68c8;
	background: rgba(186, 104, 200, 0.1);
	border-radius: 8px;
	height: 880px;
	color: #fff;
	display: flex;
	flex-direction: column;
	position: relative;

	&.is_vesting {
		height: 976px;
	}

	@include media($phone_all...) {
		height: 535px;
	}

	.reward_progression_mask {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.80);
		border-radius: 10px;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
	}

	b {
		font-weight: 700;
	}

	p {
		font-size: inherit;
	}

	.emptyMessage {
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		text-align: center;
		padding: 10px;
		margin-top: 20px;
		hyphens: none;
	}

	.header {
		padding: 16px;
		border-bottom: 1px solid #ba68c8;
		color: #fff;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		line-height: 28px;
		font-weight: 700;

		.title {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			flex-direction: column;
			gap: 8px;

			@include media($phone_all...) {
				flex-direction: row;
			}
		}

		.gem_slider {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			margin-top: 10px;

			.input_container {
				display: flex;
				gap: 8px;
				padding: 0 14px;

				input {
					background-color: #09090b;
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					border-radius: 6px;
					opacity: 0.25;
					height: 28px;
					border: 1px solid #000;
				}

				button {
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					border-radius: 6px;
					background: #ba68c8;
					height: 32px;
					display: flex;
					align-items: center;

					svg {
						font-size: 14px;
					}
				}
			}
		}

		.info,
		.fgr_change {
			border-radius: 6px;
			padding: 10px;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			hyphens: none;

			@include media($phone_all...) {
				font-size: 14px;
			}
		}

		.fgr_change {
			border: 1px solid rgba(0, 0, 0, 0.25);
			background: rgba(0, 0, 0, 0.25);
		}
	}

	.table_container {
		overflow: hidden;
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
			border-radius: 6px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
			border-radius: 6px;
		}

		scrollbar-width: 8px;
		scrollbar-color: #ba68c8 transparent;

		.table_header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 8px;
			font-size: 14px;
			gap: 8px;
		}

		table {

			td,
			tr {
				border: none;
				color: #fff;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 14px;
				padding: 12px 10px;
			}
		}
	}

	.speed_up {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 32px;
		font-family: 'IBM Plex Sans';
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin-top: 8px;
		margin-bottom: 20px;

		button {
			border-radius: 6px;
			background: #2196f3;
			font-weight: 500;

			&:disabled {
				opacity: 0.5;
				color: #fff;
			}
		}
	}
}