@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@mixin flex-box(
	$flex-direction: row,
	$justify-content: flex-start,
	$align-items: flex-start
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

.detailsBox {
	z-index: 997 !important;

	.dialogPaper {
		position: relative;
		width: 384px;
		border-radius: 30px;
		color: #fff;
		margin-top: 12%;
		height: 401px;
		background: linear-gradient(180deg, #2d2ab4 0%, #14124e 100%);
		border: 1px solid rgba(46, 43, 186, 1);
		@include media($phone_all...) {
			margin: 0px;
		}
		.dialogContent {
			padding: 32px 24px;
			overflow: hidden;
			@include flex-box(column, space-between, center);
			gap: 16px;

			.Header {
				h2 {
					font-family: Titillium Web;
					font-size: 30px;
					font-weight: 700;
					line-height: 30px;
					text-align: center;
					color: rgba(255, 255, 255, 1);
				}
			}

			.contentBox {
				background: rgba(78, 75, 222, 0.2);
				border: 1px solid rgba(78, 75, 222, 1);
				width: 336px;
				height: 224px;
				padding: 16px;
				border-radius: 8px;

				ul li {
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
					text-align: right;
				}

				.listOne {
					text-wrap: nowrap;
					@include flex-box(column, space-between, flex-start);

					li {
						font-weight: 600;
					}
				}

				.listTwo {
					text-wrap: nowrap;
					@include flex-box(column, space-between, flex-end);
				}

				@include media($phone_all...) {
				}
			}

			.closeButton {
				margin-top: 8px;

				button {
					width: 336px;
					height: 40px;
					animation-timing-function: ease-out;
					animation-duration: 300ms;
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					color: rgba(255, 255, 255, 1);
					background: rgba(78, 75, 222, 1);
					border-radius: 25px;
				}

				@include media($phone_all...) {
				}
			}
		}
	}
}
