@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@keyframes Gradient {
	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: -33% 50%;
	}
}

.overview {
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
	transition: all 1s ease-in-out;
	gap: 16px;

	@include media($phone_all...) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
		max-width: 100% !important;
		column-gap: 10px;
	}

	&>div {
		width: 100%;
		border-radius: 16px;
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 242px;

		.adornment {
			width: 5px;
			height: 53px;
			border-radius: 0px 100px 100px 0px;
			position: absolute;
			top: 30px;
			left: 0;
		}

		h1 {
			font-size: 24px;
			font-weight: 700;
		}

		.top {
			width: 100%;
			text-align: center;

			h2 {
				margin-right: 0;
				font-weight: 700;
				opacity: 1;
				font-size: 1rem;
				display: inline-flex;
				align-items: center;
				justify-content: center;

				@include media($phone_all...) {
					font-size: 0.9rem;
				}

				span {
					font-weight: 300;
					font-size: 12px;
					position: relative;

					svg {
						margin: 0;
						display: inline;
						color: #fff;
						transform: scaleX(-1);
					}
				}
			}

			.h3 {
				font-weight: 400;
				font-size: 14px;
			}

			.wallet_info {
				width: 100%;
				text-align: center;

				@include media($phone_all...) {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0px 11px;
				}

				.help_icon {
					text-align: center;
					position: absolute;
					right: 0;
					top: 7px;
					cursor: pointer;
					opacity: 1;
					right: -9px;

					svg {
						top: 4px;
						font-size: 8px;
						margin: 0;
						display: block;
						color: #fff;

						@include media($phone_all...) {
							font-size: 10px;
						}
					}
				}

				h2.text {
					@include media($phone_all...) {
						width: 100%;
					}
				}

				button {
					padding: 4px 16px;
					color: #fff;
					border-radius: 8px;
					background: #2e2bb7cc;
					font-size: 12px;

					svg {
						font-size: 12px;
					}
				}
			}
		}

		.flex_center {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}

		.center {
			text-align: center;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			img {
				position: relative;
				top: 2px;
				display: inline-block;
				width: 32px;
				height: auto;
				margin-right: 10px;
			}

			.countdown {
				font-size: 12px;
				font-weight: 600;
				margin-bottom: 4px;
				min-height: 25.59px;
			}

			.flex_Gems {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 8px auto;

				&.gem_text {
					font-family: var(--font-manrope);
					font-weight: 600 !important;
					line-height: 24px !important;
					text-align: center;
				}

				& h3 {
					flex: 1 1 20%;
					max-width: 20%;
					text-wrap: nowrap;
					font-size: 1rem !important;
					font-family: var(--font-manrope);

					&.flex_width {
						flex: 1 1 40%;
						font-size: 0.75rem !important;
						font-weight: 600;
						max-width: fit-content;
						margin-right: 8px;
					}
				}

				& img {
					flex: 1 1 17px;
					max-width: 17px;
					margin: 0px;
					margin-bottom: 4px;
					margin-right: 5px;
				}
			}

			h1 {
				display: inline-block;
			}

			.speedUpBtn {
				display: block;
				// margin-top: 12px;
				font-size: 16px;
				font-weight: 600;
				width: 100%;
				border: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 8px;
				max-height: 40px;

				border-radius: 12px;

				img {
					width: 16px;
					margin-right: 4px;
				}

				&:disabled {
					opacity: 0.6;
					color: #fff;
				}

				background-image: linear-gradient(270deg, #4e4bde, #bd02b2, #5348dc);
				background-size: 400% 100%;
				transition: all 1s ease-in-out;
				animation: Gradient 11s linear 1s infinite both;
				animation-timing-function: ease-in-out;
			}
		}

		.bottom {
			text-align: center;

			button {
				padding: 4px 16px;
				margin-top: 11px;
				color: rgba(255, 255, 255, 0.5);
				border-radius: 8px;
				background: #2e2bb7cc;
				font-size: 16px;

				svg {
					font-size: 16px;
				}
			}

			h2 {
				font-size: 13px;
				font-weight: 600;
			}

			.tokenStats {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				h3 {
					font-weight: 400;
					font-size: 12px;
					color: #ffffff;
					opacity: 1;
					line-height: 17px;

					svg {
						font-size: 10px;
						margin-right: 2px;
						position: relative;
						top: -1.5px;
					}
				}
			}
		}

		@include media($phone_all...) {
			max-width: 100% !important;
		}
	}

	.text {
		font-family: var(--font-manrope);
		font-style: normal;
		line-height: 32px;
		letter-spacing: -0.018em;
		color: #ffffff;

		span {
			cursor: pointer;
			margin-left: 10px;
			top: 0px;
			margin-left: 8px;

			svg {
				position: relative;
				font-size: 9px;
			}
		}
	}

	small {
		display: inline-block;
		font-size: 0.8rem;
		line-height: 1rem;
		margin: 1rem 0;

		@include media($phone_all...) {
			line-height: 1.1rem;
			margin: 0.75rem 0;
		}

		&.danger {
			color: #d32f2f;
		}
	}

	.total_balance {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);


		@include media($phone_all...) {
			width: 100%;
			margin-right: 0;
			flex: 1 1 48%;
			max-width: 48%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
		}

		.body {
			padding: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			h1 {
				font-size: 32px;
				margin: 15px auto;

				@include media($phone_all...) {
					font-size: 32px;
				}
			}

			h2 {
				@include media($phone_all...) {
					display: flex;
					align-items: center;
					justify-content: center;
					text-wrap: nowrap;
				}

				span {
					@include media($phone_all...) {
						display: block;
						top: 0px;
					}
				}
			}

			.text {
				line-height: initial;
			}

			@include media($phone_all...) {
				height: 100%;
				display: flex;
				align-items: stretch;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		.adornment {
			background: #9664ff;
		}
	}

	.rewards_locked {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(26, 23, 62, 0.9);
		z-index: 1;
		border-radius: 16px;
		height: 100%;
		max-width: 100% !important;

		button {
			font-size: 16px !important;
			font-weight: 600;
			background-image: none !important;
			padding: 0.7rem 1.75rem !important;
		}

		button::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 5px;
			border: 0.15rem solid transparent;
			background: linear-gradient(45deg, #016eda, #d900c0) border-box;
			-webkit-mask: linear-gradient(#fff 0 0) padding-box,
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
		}
	}

	.earnings {
		justify-content: space-between;

		@include media($phone_all...) {
			margin-bottom: 15px;
			flex: 1 1 48%;
			max-width: 48%;
		}

		.locked,
		.unlocked {
			border-radius: 16px;
			border: 1px solid #4e4bde;
			flex: 1 1 47%;
			max-height: 47%;
			background: linear-gradient(207.51deg,
					rgba(78, 75, 222, 0.25) -8.93%,
					rgba(26, 23, 62, 0.25) 89.5%);

			h2 {
				@include media($phone_all...) {
					display: flex;
					align-items: center;
					justify-content: center;
					text-wrap: nowrap;
				}

				span {
					@include media($phone_all...) {
						display: block;
						top: 0px;
					}
				}
			}

			.text {
				line-height: initial;
			}

			.body {
				padding: 15px;

				@include media($phone_all...) {
					padding: 15px 8px;
				}
			}

			img {
				width: 20px;
			}
		}

		@include media($phone_all...) {
			width: 100%;
			margin-right: 0;
		}

		.adornment {
			background: #ffffff;
		}

		.help_icon {
			position: absolute;
			right: 0;
			cursor: pointer;
			opacity: 0.5;
			height: 20px;

			svg {
				font-size: 15px;
				margin: 0;
				float: left;

				@include media($phone_all...) {
					font-size: 20px;
				}
			}

			&:hover {
				opacity: 1;
			}
		}

		.top {
			text-align: center;
		}
	}

	.exchange {
		width: auto;
		height: initial;
		display: flex;
		justify-content: center;
	}

	.speedUp {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 16px;
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);
		padding: 16px;

		@include media($phone_all...) {
			margin-bottom: 15px;
			margin-right: 0px !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.body {
			height: 100%;

			@include media($phone_all...) {
				flex: 1 1 75%;
				max-width: 75%;
			}

			.top {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		h2 {
			font-family: var(--font-manrope);
			font-weight: 700;
			line-height: 24px;
			letter-spacing: -0.018em;
			text-align: center;
			text-wrap: nowrap;
			display: flex;
			flex-wrap: nowrap;

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				top: 0px;
				margin-left: 8px;

				svg {
					position: relative;
					font-size: 9px;
				}

				img {
					width: 17px;
					height: 17px;
				}
			}
		}

		.flex_column {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			justify-content: center;
			gap: 0px;
		}
	}

	.external_wallet {
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;

		button {
			width: 275px;
			margin-bottom: 10px;
		}

		p {
			width: 275px;
			color: #fff;
			text-align: center;
			font-family: var(--font-manrope);
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: -0.18px;
		}
	}

	.row_group {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 100% !important;
		justify-content: flex-start;
		max-height: 200px;
		gap: 16px;

		@include media($phone_all...) {
			margin-bottom: 15px;
			flex-wrap: nowrap;
			flex-direction: row;
			max-height: 100%;
			gap: 16px;
			justify-content: space-between;

			.total_balance {
				order: 1;
				flex: 1 1 48%;
				max-width: 48%;
				min-width: auto !important;
				min-height: 190px;
			}
		}

		.total_balance {
			border-radius: 16px;
			width: 215px;
		}
	}
}

.transferIn {
	.dialog_paper {
		width: 100%;
		max-width: 348px;
		position: relative;
		background: linear-gradient(180deg,
				rgba(45, 42, 180, 0.5) 0%,
				rgba(20, 18, 78, 0.85) 50%);
		border: 1px solid #2e2bba;
		border-radius: 30px;
		color: #fff;
		padding: 32px 25px 20px;
		backdrop-filter: blur(5px);

		@include media($phone_all...) {
			max-width: 400px;
			margin: 0.5rem;
			padding: 15px;
		}

		.close_btn {
			position: absolute;
			right: 12px;
			top: 12px;
			z-index: 100;
		}

		input {
			background: #2e2c6a;
			border: 1px solid #5553da;
			border-radius: 8px;
		}

		.dialog_content {
			padding: 0;
			text-align: center;

			h1 {
				font-size: 20px;
				font-weight: 600;
				text-align: center;
			}

			p {
				text-align: center;
				color: rgba(255, 255, 255, 0.5);
				margin: 10px;

				&.warning {
					font-size: 12px;
					line-height: 14px !important;
				}
			}

			.illustration {
				width: 70%;
				margin-top: 14px;
			}

			.walletCopy {
				position: relative;
				margin-top: 20px;
				background: #2e2c6a;
				border-radius: 8px;
				padding: 12px 20px;
				font-size: 16px;
				text-align: left;

				button {
					width: 70px;
					position: absolute;
					right: 16px;
					top: 8px;
					text-transform: uppercase;
					border: none;
					background: #4e4bde;
					color: #fff;
					font-family: 'Titillium Web', sans-serif;
					font-size: 16px;
					padding: 5px;
					margin: 0;
					cursor: pointer;
				}
			}

			.subTitle {
				font-size: 10px;
			}

			button {
				width: 100%;
				background: #2e2c6a;
				border-radius: 25px;
				margin-top: 10px;
				font-weight: 600;
				font-size: 16px;
			}

			@include media($phone_all...) {
				overflow: initial;
			}
		}
	}
}