@import 'ThemeScss';

.team-tab-section {
	.team-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 2rem 0 2%;
		background-color: #171717;

		.tabs-list {
			border: none;
			padding: 0;

			button {
				color: #fff !important;
			}
		}
	}

	.member-list {
		padding: 1rem;
		margin-bottom: 1rem;
		background: #171717;
		color: $whiteColor;

		.member {
			display: inline-flex;
			width: 100%;
			position: relative;

			.media {
				img {
					width: 5rem;
					border-radius: 50%;
					margin-right: 1rem;
				}
			}

			.media-text {
				a {
					color: $whiteColor;
					display: inline-flex;
					width: 100%;
					align-items: center;
					margin-bottom: 0.8rem;

					h5 {
						margin: 0;
						font-weight: 600;
						font-size: 1.1rem;
					}

					.role {
						font-size: 0.8rem;
						background: $linkColorOther;
						padding: 0.25rem 0.75rem;
						border-radius: 0.25rem;
						margin-left: 0.5rem;
					}

					&:hover {
						text-decoration: none;
					}
				}
			}

			svg {
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
			}
		}

		p {
			line-height: 1.5rem;
			margin: 0.75rem 0;
			color: #bcb2aa;

			a {
				color: #5ad2f2;
			}
		}
	}

	.add-radio-group {
		width: 100%;
		padding: 65px 0 0 50px;
	}
}
