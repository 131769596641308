@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.sidebar_dashboard {
	position: absolute;
	z-index: 100;
	left: 0rem;
	top: 0.5rem;
	height: auto;
	width: 16.5rem;
	overflow: auto;

	@include media($phone_all...) {
		display: none;
		top: 11.5rem;
		z-index: 100;
		background: #000;
	}
}
