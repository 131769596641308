.profile_card {
	padding: 0 0.1rem;
	position: relative;
	background: radial-gradient(
		123.22% 129.67% at 100.89% -5.6%,
		#201d47 0%,
		#17153a 100%
	);
	box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	display: flex;
	height: 100%;

	.header {
		display: flex;
		justify-content: center;
		overflow: hidden;
		background: none;
		position: relative;
		width: 6rem;

		.header_img {
			width: 100%;
			position: absolute;
		}

		svg {
			width: 2.2rem !important;
			height: 2.2rem !important;
		}

		a {
			margin: auto;

			div {
				width: 3rem !important;
				height: 3rem !important;
				margin-right: 0;
			}
		}
	}

	.content {
		border-radius: 0 0 5px 5px;
		background: none;
		padding: 12px;
		height: 100%;
		width: 100%;

		.user_info {
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;

			a {
				display: flex;
				flex-direction: column;
				color: #fff;

				.display_name {
					font-size: 1.2rem;
					font-weight: 700;
				}

				.username {
					font-size: 1rem;
					font-weight: 300;
				}
			}

			button {
				color: #fff;
				background: #000;
				border-radius: 6px;
				padding: 0px 12px;
				height: 35px;

				svg {
					font-size: 1rem;
				}
			}
		}

		.about {
			margin-top: 0.25rem;
			font-weight: 300;
			margin-bottom: 0;
			font-size: 0.7rem;
			line-height: 1rem;
			letter-spacing: 0;
		}
	}
}
