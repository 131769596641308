@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.activity_table {
	height: 680px;
	margin-top: 20px;
	border-radius: 20px;
	background: #1a173e;
	padding: 20px 30px;
	position: relative;

	@include media($phone_all...) {
		height: 700px;
	}

	.table_container {
		max-height: 470px;
		margin-bottom: 12px;
		display: flex;

		p {
			align-self: center;
			width: 100%;
			text-align: center;
		}

		.fraud_icon {
			margin-left: 5px;
			position: relative;

			svg {
				color: #ff144b;
				font-size: 16px;
				border: none;
			}
		}

		.retry {
			background: #e9010130 !important;
			height: 26px;

			&:disabled {
				color: #ffffff6b;
				font-weight: 400;
			}
		}
	}

	.text {
		font-family: var(--font-inter), sans-serif;
		font-style: normal;
	}

	* {
		background: transparent !important;
	}

	.tableheader {
		text-transform: uppercase;
		font-family: 'var(--font-manrope)';
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 17px;
		letter-spacing: -0.018em;
		color: #ffffff !important;
		opacity: 0.6;
	}

	.text_tbody {
		font-family: var(--font-inter), sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: #ffffff;
		border: none;
		padding-left: 0;
	}

	table {
		thead {
			th {
				border: none;
				background-color: #1b173e !important;

				span {
					@extend .tableheader;

					&:hover {
						@extend .tableheader;
					}
				}

				svg {
					font-size: 16px;
					color: #ffffff !important;
				}
			}
		}

		tbody {
			td {
				@extend .text_tbody;

				a {
					@extend .text_tbody;
				}
			}

			svg {
				border: 1px solid #484d51;
				border-radius: 50%;
				font-size: 12px;
				padding: 4px;
			}

			.unstakeButton {
				&:disabled {
					color: rgba(255, 255, 255, 0.2);
				}
			}

			.green {
				color: #1e8f2a;
			}

			.red {
				color: #f82e2e;
			}
		}
	}

	.pagination {
		* {
			color: #ffffff;
		}

		button {
			&:disabled {
				* {
					color: #ffffff52;
				}
			}
		}
	}
}

.header {
	display: flex;
	margin: 8px 5px 10px;

	.only_container {
		display: flex;
		align-items: center;
	}

	@include media($phone_all...) {
		flex-direction: column;

		.only_container {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
		}
	}

	.title {
		@extend .text;
		font-weight: 600;
		font-size: 14px;
		line-height: 50px;
		color: #ffffff;
		margin-right: auto;
	}

	.view_only {
		@extend .text;

		color: #cdff65;
		text-align: right;
		font-family: var(--font-inter), sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		cursor: pointer;

		&.selected {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	.view_only_earnings {
		@extend .view_only;
		margin-left: 25px;
		margin-right: 50px;

		@include media($phone_all...) {
			margin-left: 20px;
			margin-right: 0;
		}
	}
}