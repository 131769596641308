@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.claim_wallet_dialog {
	.dialog_paper {
		background-color: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: none !important;

		.header {
			position: relative;
			z-index: 10;
			width: 340px;
			height: 155px;
		}
		.header_background {
			z-index: 10;
			width: 100%;
			height: auto;
		}
		.exclamationIcon {
			position: absolute;
			z-index: 20; // Ensure it's above the background image
			top: 40%; /* Center vertically */
			left: 50%; /* Center horizontally */
			transform: translate(-50%, -50%); /* Adjust for exact centering */
			z-index: 20; /* Ensure it's above the background image */
			width: 50%;
			height: auto;
			max-height: 75px;
			max-width: 75px;
		}

		.content {
			z-index: 10;
			padding-left: 10%;
			padding-right: 10%;
			overflow: auto;
		}
	}

	.dialog_background {
		height: 90%;
		width: 100%;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		position: absolute;
		bottom: 0;
	}

	h1 {
		letter-spacing: normal;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 46px;
		color: #fff;
		text-align: center;
		margin-bottom: 5px;
	}

	p {
		color: #fff;
		letter-spacing: normal;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
	}

	ol {
		li {
			list-style: auto;
			line-height: 20px;
			margin: 10px 0;
		}
	}

	.agree {
		letter-spacing: 1px;
		font-size: 0.7rem;
		font-weight: 300;
		margin: 20px 0;

		p {
			color: #fff;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: normal;
		}
	}

	.controls {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			background-color: #4e4bde;
			border-radius: 25px;
			width: 106px;
			height: 35px;
			margin-left: 10px;
			margin-bottom: 20px;
			color: #fff;

			&:disabled {
				opacity: 0.4;
			}

			a {
				color: #fff;
			}

			&.cancel {
				background-color: #fff;
				color: #000;
			}
		}
	}
}
