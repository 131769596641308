@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.filters_item {
	width: 100%;
	position: relative;
	background: none;
	padding: 1rem;

	.close_btn {
		display: none;

		@include media($phone_all...) {
			right: 10px;
			top: 10px;
			display: block;
			position: absolute;
			z-index: 100;

			svg {
				font-size: 16px;
			}
		}
	}

	.logo {
		display: none;

		@include media($phone_all...) {
			display: block;
			height: 40px;
		}
	}

	.sorter {
		float: left;
		font-size: 1.2rem;
		margin-top: 0.9rem;
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.select_box {
		display: flex;
		align-items: center;

		svg {
			margin-bottom: 15px;
			margin-right: 10px;
		}
	}

	.menu_item {
		display: flex;
		align-items: center;
		position: relative;

		& > svg,
		& > img {
			height: 16px;
			width: 16px;
			margin-right: 3px;
		}

		.only_show_label {
			span {
				margin-left: -10px;
				color: #fff;
				font-size: 1rem;
				font-weight: 300;

				&:first-child {
					opacity: 0;
				}
			}

			&.active {
				span {
					color: #fff;
					font-weight: 600;
				}
			}
		}

		.adoption,
		.dev,
		.distro {
			height: 1rem;
			width: 1rem;
			border-radius: 50%;
			background: #fc8e0b;
		}

		.dev {
			background: #bd1f40;
		}

		.distro {
			background: #953b96;
		}
	}

	h3 {
		letter-spacing: 0.1rem;
		margin: 15px 0 0;
		display: inline-block;
		text-transform: uppercase;
		color: #fff;
		font-size: 0.8rem;
		font-weight: 300;
		width: 100%;

		&.mt_30 {
			margin-top: 10px;
			margin-bottom: 10px;

			@include media($phone_all...) {
				margin-top: 0 !important;
			}
		}

		&.mt_40 {
			margin-top: 40px;
		}
	}
}

.helpIcon {
	cursor: pointer;

	svg {
		color: #fff;
		font-size: 13px;
		position: absolute;
		top: 1rem;
		right: 0;

		@include media($phone_all...) {
			top: 0.75rem;
			font-size: 20px;
		}
	}
}
