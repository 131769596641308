@import '~include-media/dist/include-media';
@import 'Variable.scss';
@import 'MediaQuery.scss';

.user_item {
	width: 280px;
	height: 266px;
	display: flex;
	flex-direction: column;
	position: relative;
	border: 2px solid #1aeff3;
	border-radius: 25px;
	margin: 20px 10px 15px;
	overflow: hidden;

	.follow {
		position: absolute;
		right: 11px;
		top: 11px;

		button {
			width: 26px;
			height: 26px;
			border-radius: 50%;
			padding: 18px;

			svg {
				font-size: 16px;
			}

			&.unfollow_btn {
				background-color: #000;
			}

			&.follow_btn {
				background-color: #3cbb5b;
			}
		}
	}

	.mid_section {
		position: absolute;
		top: 35%;

		.avatar {
			border-radius: 50%;
			border: 2px solid #fff;
			width: 57px;
			height: 57px;
			margin-left: 20px;
			margin-right: 10px;
		}

		.badge {
			width: 20px;
			height: 16px;
		}
	}

	.header {
		width: 100%;
		flex-basis: 45%;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.content {
		padding: 0 20px;
		padding-top: 30px;
		background-color: #152d53;
		flex-basis: 55%;
		border-radius: 0 0 25px 25px;

		a {
			color: #fff;
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;

			svg {
				position: relative;
				color: #1aeff3;
				font-size: 13px;
				margin-left: 4px;
				top: -1px;
			}
		}

		.about {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;

			@include media($phone_all...) {
				max-height: 4rem;
			}
		}
		p {
			color: #fff;
			font-size: 10px;
			line-height: 13px;
		}
	}
}
