@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.tokens_section {
	height: 100%;
	min-width: 256px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	.green_info {
		position: absolute;
		cursor: pointer;
		top: -13px;
		right: 5px;
	}

	@include media($phone_all...) {
		min-width: auto !important;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 0px;
		min-height: 190px;
		order: 2;
		flex: 1 1 48%;
		max-width: 48%;
		height: auto;

		.arv_token {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-height: 58px;
			gap: 0px;

			h3:not(.available_token),
			h2 {
				line-height: 20px !important;
			}

			h3.available_token {
				line-height: 12px !important;
			}
		}
	}

	.arv_token {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		min-height: 53px;
		padding: 10px 15px 10px 15px;
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				#4e4bde40 -8.93%,
				#1a173e40 89.5%);
		border-radius: 16px;

		.body {
			@include media($phone_all...) {
				display: flex;
				gap: 8px;
				flex-direction: column;
			}

			padding: 13px;
		}

		@include media($phone_all...) {
			background: linear-gradient(180deg,
					#4e4bde80 0%,
					#1a173e80 100%);
			max-height: 58px;
			max-width: 168px !important;
			justify-content: center;

			&:first-child {
				min-width: 140px;
				align-items: flex-start;
			}
		}

		&.small_gap {
			gap: 6% !important;
			padding: 10px;

			.right_section {
				gap: 8px;

				h3.header_tokens {
					.available_to_withdraw {
						font-size: 9px;
					}
				}
			}

			@include media($phone_all...) {
				display: flex;
				align-items: center;
			}
		}

		.right_section {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			span {
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}

			h3.header_tokens {
				font-family: var(--font-manrope) !important;
				font-size: 12px !important;
				font-weight: 700 !important;
				line-height: 32px;
				letter-spacing: -0.018em !important;
				max-width: 100px;
				min-width: 100px;
				padding: 0px;
				margin: 0px;
				line-height: normal;
			}
		}

		.left_section {
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			margin-left: 0%;
			margin-right: 5%;
			gap: 8px;
			font-variant-numeric: tabular-nums;
			width: -webkit-fill-available;

			@include media($phone_all...) {
				justify-content: center;
			}

			img.width_16 {
				width: 16px;
				height: 16px;
			}

			h2.text {
				font-family: var(--font-manrope);
				font-size: 20px;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: -0.018em;
				text-align: left;
			}

			&.small_font {
				gap: 5px;
				width: auto;

				@include media($phone_all...) {
					justify-content: center;
					display: flex;
				}

				h2.text {
					font-size: 0.78rem !important;
				}
			}
		}
	}
}