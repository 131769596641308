@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.content {
	border-radius: 15px;
	background: #292375;
	padding: 32px;

	@include media($phone_all...) {
		padding: 18px;
	}

	.transfer_text {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;

		.help_icon {
			position: relative;
			top: -2px;
			cursor: pointer;
			opacity: 0.5;
			height: 15px;
			margin-left: 5px;

			svg {
				font-size: 14px;
				margin: 0;

				@include media($phone_all...) {
					font-size: 20px;
				}
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	.input_container {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		align-items: center;

		input {
			font-size: 52px;
			font-weight: 700;
			font-family: 'Titillium Web';
			line-height: 56px;
			letter-spacing: 4px;
			border: none !important;
			background: transparent !important;
			color: #fff;
			width: 60%;

			&::placeholder {
				color: #fff;
				opacity: 0.25;
			}

			@include media($phone_all...) {
				font-size: 40px;
			}

			&:focus-visible {
				border: none !important;
				background: transparent !important;
				color: #fff;
				outline: none;
			}
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		p {
			border-radius: 7.5px;
			background: #2e2bb7;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: normal;
			padding: 4px 12px;

			@include media($phone_all...) {
				font-size: 12px;
				line-height: 20px;
			}

			svg {
				font-size: 14px;
				margin-right: 8px;

				@include media($phone_all...) {
					font-size: 10px;
					margin-right: 6px;
				}
			}
		}
	}

	.bottom {
		display: flex;
		align-items: center;

		p {
			color: rgba(255, 255, 255, 0.5);
			letter-spacing: normal;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			margin-top: 10px;
			width: 100%;

			svg {
				color: rgba(255, 255, 255, 0.5);
				position: relative;
				top: -1px;
				font-size: 11px;
			}

			small {
				font-weight: 300;
				margin-left: 5px;
			}
		}

		strong {
			cursor: pointer;
		}

		button {
			min-width: initial;
			text-transform: uppercase;
			border-radius: 5px;
			background: #2e2bb7;
			padding: 2px 8px;
			color: #f768ca;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-top: 10px;
			margin-left: 10px;
		}
	}
}

.transfer {
	width: 100%;
	margin-top: 16px !important;
	font-size: 20px !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: 24px !important;
	border: 0.1rem double transparent !important;
	background-image: radial-gradient(circle at left top, #4e4bde, #4e4bde),
		radial-gradient(circle at left top, #d900c0, #016eda) !important;
	background-origin: border-box !important;
	background-clip: padding-box, border-box !important;
	border-radius: 25px !important;
}

.email_verification {
	padding: 8px 8px 0;

	h1 {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 30px;
		margin-bottom: 8px;
	}

	p {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 16px;
		letter-spacing: normal;

		span {
			cursor: pointer;
			color: #53abc9;
		}
	}

	input {
		text-align: center;
	}

	.actions {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		button {
			flex: 1;
			margin: 0 4px;
			border-radius: 40px;
		}
	}
}

.kyc_content {
	.title {
		font-size: 20px;
		margin-bottom: 10px;
		font-weight: 600;
	}

	p {
		text-align: center;
	}

	.footer {
		padding: 20px 0 10px;
		display: flex;
		justify-content: center;
		gap: 10px;

		button {
			border-radius: 15px;

			&:first-child {
				background: #1d1b80;
			}
		}
	}
}
