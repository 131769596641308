@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stats {
	position: relative;
	width: 100%;
	display: flex;
	padding: 4rem 0;
	.stat {
		background: #1a173e;
		width: 287px;
		height: 195px;
		margin-right: 10px;
		border-radius: 16px;
		padding: 40px;
		position: relative;
		display: flex;
		flex-direction: column;
		h3 {
			font-weight: 300;
			font-size: 13px;
			letter-spacing: 0rem;
			color: #8a8a9c;
			text-transform: uppercase;
			margin: 0;
		}

		span {
			margin-top: 18px;
			font-size: 50px;
			font-weight: bold;
		}
	}
}

.project {
	position: relative;
	width: 100%;
	min-height: 30rem;
	display: flex;
	padding: 0 0 4rem;
	@include media($phone_all...) {
		padding-bottom: 1rem;
		margin-top: 2rem;
	}
	.title {
		position: absolute;
		text-transform: uppercase;
		color: rgba(35, 63, 92, 0.5);
		font-size: 6rem;
		font-weight: 600;
		top: 2%;
		left: 4%;
		text-shadow: -0.3rem -0.3rem rgb(35 63 92 / 50%);

		@include media($phone_all...) {
			font-size: 4rem;
			top: 44px;
		}
	}
	.content {
		width: 1200px;
		padding-top: 4rem;
		position: relative;
		z-index: 2;

		@include media($phone_all...) {
			max-width: 350px;
			padding-top: 0;
		}

		.draft {
			display: flex;
			justify-content: flex-start;

			@include media($phone_all...) {
				flex-direction: column;
				align-items: center;
			}
			.draftDesc {
				max-width: 33%;
				margin-top: 2rem;
				margin-right: 2rem;

				@include media($phone_all...) {
					max-width: 90%;
					margin: 0 auto 30px;
				}

				h3 {
					font-weight: 300;
					line-height: 2rem;
				}

				p {
					font-size: 1rem;

					line-height: 1.4rem;
				}
			}
		}

		.pending {
			display: flex;
			justify-content: flex-start;

			@include media($phone_all...) {
				flex-direction: column;
				align-items: center;
			}

			.pendingDesc {
				max-width: 33%;
				margin-top: 2rem;
				margin-right: 2rem;

				@include media($phone_all...) {
					max-width: 90%;
					margin: 0 auto 30px;
				}

				h3 {
					font-weight: 300;
					line-height: 2rem;
				}

				p {
					font-size: 1rem;

					line-height: 1.4rem;
				}
			}
		}

		.publishd {
			display: flex;
			justify-content: flex-start;

			@include media($phone_all...) {
				flex-direction: column;
				align-items: center;
			}

			.publishdDesc {
				max-width: 33%;
				margin-top: 2rem;
				margin-right: 2rem;

				@include media($phone_all...) {
					max-width: 95%;
					margin: 0 auto 0px;
				}

				h3 {
					font-weight: 300;
					line-height: 2rem;
				}

				p {
					font-size: 1rem;
					line-height: 1.4rem;
					margin-bottom: 1rem;

					strong {
						color: #56adcc;
					}
				}
			}
		}
	}
}
