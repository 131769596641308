@import 'ThemeScss';

.forgot-password-section {
	float: left;
	width: 100%;
	margin-top: 9.375rem;
	position: relative;
	z-index: 1;

	.forgot-form {
		margin: 0 auto;
		border-radius: 20px;
		padding: 68px 48px;
		position: relative;
		max-width: 600px;
		width: 100%;
		background: rgba(0, 0, 0, 0.35);
		text-align: center;

		.back {
			color: #5dccf2;
			position: absolute;
			left: 0;
			top: -55px;
			font-size: 17px;
			font-weight: 600;
			line-height: 20px;
		}

		h2 {
			font-weight: 300;
			text-align: center;
			letter-spacing: 0.0625rem;
			padding: 0 20px;
			font-size: 42px;
			margin-top: 0;
			text-align: center;
		}

		p {
			text-align: center;
			color: $whiteColor;
			font-size: 15px;
		}

		form {
			margin-bottom: 2.5rem;
			padding: 0rem 2rem;
		}

		.forgot-password-form {
			> label {
				font-size: 15px;
				margin-bottom: 12px;
				display: none;
			}

			button.yellow-bg-button-forgot {
				font-family: 'Titillium Web';
				margin-top: 1rem;
				border-radius: 25px;
				background: #4e4bde;
				height: 35px;
				min-width: 136px;
				color: #fff;
				text-transform: none;
				font-size: 1.4rem;
				padding: 0.7rem 0;
				line-height: inherit;
				height: inherit;
				text-align: center;
				height: 33px;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}
