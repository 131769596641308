@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.row_group {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 100% !important;
	justify-content: flex-start;
	max-height: 200px;
	gap: 10px;

	@include media($phone_all...) {
		margin-bottom: 15px;
		flex-wrap: wrap;
		flex-direction: row !important;
		max-height: 100%;
		row-gap: 16px;
		column-gap: 0px;
		justify-content: space-between;

		.total_tokens {
			order: 1;
			flex: 1 1 48%;
			max-width: 48%;
			min-width: auto !important;
			min-height: 190px;

			.number_copy {
				@include media($phone_all...) {
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.diamond_circle_HODL {
			order: 3;
			flex: 1 1 100%;
			max-width: 100%;
			min-width: auto;
		}
	}

	.total_tokens,
	.diamond_circle_HODL {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.total_tokens,
	.diamond_circle_HODL,
	.arv_token {
		border: 1px solid #4e4bde;
		background: linear-gradient(207.51deg,
				rgba(78, 75, 222, 0.25) -8.93%,
				rgba(26, 23, 62, 0.25) 89.5%);
		border-radius: 16px;

		.body {
			padding: 13px;


			.top {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				.header_token_hodl {
					font-family: var(--font-manrope);
					font-size: 16px;
					font-weight: 700;
					line-height: 32px;
					letter-spacing: -0.018em;
					display: flex;
					flex-wrap: nowrap;
					justify-content: center !important;
					margin: 0px;
					gap: 8px;

					span {
						font-size: medium;

						img {
							width: 17px;
							height: 17px;
						}

						display: flex;
						align-items: center;
						justify-content: center;
						top: 0px;

						&::before {
							display: none !important;
						}
					}
				}
			}

			.number_copy {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;

				.center {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;

					.text {
						font-family: var(--font-manrope);
						font-style: normal;
						line-height: 32px;
						letter-spacing: -.018em;
						color: #fff;
						font-size: 18px;
						font-weight: 700;
					}

					img {
						height: 20px;
					}
				}

				button {
					padding: 4px 16px;
					border-radius: 8px;
					background: #2e2bb7cc;
					font-size: 12px;
					text-overflow: ellipsis;
					text-wrap: nowrap;
					color: #ffffff80;

					svg {
						color: #ffffff80;
						font-size: 12px;
					}
				}
			}

			@include media($phone_all...) {
				display: flex;
				gap: 8px;
				flex-direction: column;
			}

		}
	}

	.diamond_circle_HODL {
		background: linear-gradient(249.35deg,
				#cecece26 5.78%,
				rgba(156, 148, 255, 0.15) 43.38%,
				rgba(186, 183, 225, 0.15) 84.93%) !important;
		border: 1px solid #ffffff80 !important;
	}

}