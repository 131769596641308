@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.explore_section {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	width: 100%;
	margin-bottom: 65px;

	.header {
		display: flex;
		justify-content: space-between;
		margin: auto 0.5rem;

		@include media($phone_all...) {
			width: 100%;
			margin-bottom: 20px;
		}

		button {
			background-color: #152d53;
			border-radius: 25px;
			font-size: 11px;
			padding: 3px 20px;
			text-transform: uppercase;
			margin-right: 1.25rem;

			@include media($phone_all...) {
				margin-right: 0.5rem;
			}
		}

		div {
			display: flex;
			align-items: center;
			position: relative;
			z-index: 1;
			background: transparent;

			p {
				font-size: 20px;
				font-weight: 700;
				letter-spacing: normal;
				margin-left: 0.25rem;
			}

			svg {
				color: #01b6df;
				font-size: 16px;
				margin-left: 8px;
				top: -0.1rem;
				position: relative;
				cursor: pointer;
				opacity: 0.5;
				transition: all 200ms ease-in;
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.carousel {
		@include media($phone_all...) {
			max-width: 100% !important;
		}

		.carousel_item {
			max-width: 99% !important;

			@include media($phone_all...) {
			}

			> div {
				@include media($phone_all...) {
					margin-right: 5%;
				}
			}
		}

		.snapitem {
			display: flex;

			@include media($phone_all...) {
				margin-top: 2rem;
			}
		}

		.prev {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -40px;
			height: 80%;
			width: 50px;
			z-index: 11;

			&:hover {
				background: none;
			}

			@include media($phone_all...) {
				display: none;
			}
		}

		.prev_hidden {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -40px;
			height: 80%;
			width: 50px;
			z-index: 11;
			opacity: 0.25;

			@include media($phone_all...) {
				display: none;
			}
		}

		.next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -40px;
			height: 80%;
			z-index: 10;
			border-radius: 0;
			width: 50px;

			@include media($phone_all...) {
				display: none;
			}
		}

		.next_hidden {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -40px;
			height: 80%;
			z-index: 10;
			border-radius: 0;
			width: 50px;
			opacity: 0.25;

			@include media($phone_all...) {
				display: none;
			}
		}
	}
}
