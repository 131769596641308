@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.carousel {
	width: 50%;

	.snapitem {
		display: flex;

		@include media($phone_all...) {
			display: none;
		}

		a {
			&>img {
				max-height: 130px;
				border: 1px solid #ffffff;
				border-radius: 11px;
				margin-right: 25px;
			}
		}
	}

	.slide_right {
		-webkit-animation: slide-right 1s;
		animation: slide-right 1s;
	}
}

.previews {
	width: 610px;
	margin-left: 2.25rem;
	position: relative;

	@include media($phone_all...) {
		margin-left: 0rem;
		width: 350px;
	}

	.snapitem {
		display: flex;

		@include media($phone_all...) {
			margin-top: 2rem;
			margin-right: 10px;
		}
	}

	.prev {
		position: absolute !important;
		top: 50%;
		transform: translateY(-55%);
		left: -40px;
		height: 100%;
		height: 600px;
		background: none;
		z-index: 11;
		border-radius: 0;
		width: 100px;

		svg {
			position: relative;
			right: 1.5rem;
		}

		&:hover {
			background: none;
		}

		@include media($phone_all...) {
			top: 2%;
			background: none;
			left: 18px;
			height: 70px;
		}
	}

	.next {
		position: absolute !important;
		border-radius: 0 !important;
		top: 50%;
		transform: translateY(-55%);
		right: -25px;
		height: 600px;
		background: none;
		z-index: 10;
		border-radius: 0;
		width: 50px;

		@include media($phone_all...) {
			top: 2%;
			background: none;
			right: 18px;
			height: 70px;
		}

		svg {
			position: relative;
			left: 1.5rem;
		}

		&:hover {
			background: none;
		}
	}
}