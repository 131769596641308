@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.explore {
	display: flex;
	flex-direction: column;
	width: calc(100% - 15rem);
	margin-left: 15rem;

	@include media($phone_all...) {
		width: 93%;
		margin-left: 1rem;
		margin-top: 9rem;
	}

	.container {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		max-width: 76rem;

		@media all and (max-width: 1536px) {
			max-width: 57rem;
		}

		.header {
			position: relative;
			width: 97%;
			margin-top: 2rem;
			margin-bottom: 2rem;

			@include media($phone_all...) {
				display: none;
			}

			.background {
				width: 100%;
			}

			.filmio_text {
				position: absolute;
				top: 28%;
				left: 15%;
				width: 30%;
			}

			.header_text {
				position: absolute;
				top: 15%;
				right: 4%;
				width: 36%;
			}

			.banner {
				position: absolute;
				left: 8px;
				bottom: -12px;
				width: 100%;
				background: black;
			}
		}
	}
}
